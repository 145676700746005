<template>
  <v-container fluid class='pt-0 overflow-y-auto'>

    <v-row>
      <v-col>
        <v-btn class='primary' @click='create'><v-icon>mdi-plus</v-icon>New Tier</v-btn>
      </v-col>
    </v-row>

    <v-row class='px-2' ref='sortArea'>

      <v-col cols=12 lg=6 xl=4 v-for="tier in tiers" v-bind:key="tier.xid" class='pa-1' :data-id="tier.xid">
        <v-card class='tier py-2' :disabled='loading' @click='clickEdit(tier)'>
          <div class='dragMe cardbtn'><v-icon>mdi-drag</v-icon></div>
          <div class='duplicate cardbtn' @click.stop="duplicate(tier)"><v-icon small>mdi-content-copy</v-icon></div>
          <div class='delete cardbtn' @click.stop="Delete(tier)"><v-icon small>mdi-trash-can-outline</v-icon></div>

          <v-list-item class='pl-8 pr-14'>
              <template v-if="tier"><TierIcon :tier="tier" size="50px" class='mr-4' /></template>
            <v-list-item-content class='py-1'>
              <v-list-item-title class='text-body-1 py-1 font-weight-medium' v-text='`$${((tier.price||0)/100).toFixed(2)}`' />
              <v-list-item-subtitle>
                <span v-if='tier.description'>{{tier.description}}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>


    </v-row>


    <v-dialog v-model="editTier.show" v-if='editTier.show' max-width="450" persistent>
      <v-card elevation="20" :disabled='editTier.loading'>
        <v-card-title class="text-h6 primary white--text py-3">
          Update Tier
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2'>
          <v-row style='py-0'>

            <v-col cols=2 style='py-0'>

              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{on,attrs}">
                  <v-btn v-on="on" v-bind="attrs" :color="editTier.colour" fab small />
                </template>
                <v-card>
                  <v-color-picker dot-size="25" v-model="editTier.colour" mode="hexa" />
                </v-card>
              </v-menu>


            </v-col>

            <v-col cols=5 style='py-0'><v-text-field v-model='editTier.code' outlined dense hide-details label='Tier Code' maxlength=3 /></v-col>
            <v-col cols=5 style='py-0'><v-text-field v-model='editTier.price' outlined dense hide-details label='Price' prefix="$" /></v-col>
            <v-col cols=12 style='py-0'><v-textarea :value='editTier.description' @change="editTier.description = $event" outlined dense label='Description' counter="100" maxLength="100" /></v-col>

          </v-row>
        </v-card-text>
        <v-card-actions class='pb-4'>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='editTier.show = false;'>Cancel</v-btn>
          <v-btn color="primary darken-1" @click='updateTier'>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style lang="scss">
@import "@/assets/common";
.tier{
  user-select: none;
  .cardbtn{
    position:absolute;
    z-index:1;
    top: 6px;
    &.dragMe{
      cursor: move;
      left:4px;
    }
    &.duplicate{
      cursor: pointer;
      right:30px;
      &:hover .v-icon{ color: $primary; }
    }
    &.delete{
      cursor: pointer;
      right:8px;
      &:hover .v-icon{ color: $primary; }
    }
    &.tax{
      top: auto;
      bottom: 4px;
      right: 10px;
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import Sortable from 'sortablejs';
import TierIcon from '@/components/Billing/TierIcon'

export default {
  name: 'Tiers',

  components: {
    TierIcon,
  },

  data: () => ({
    loading: false,
    tiers: [],
    editTier: {
      show: false,
      loading: false,
      code: "",
      colour: "#1ec131",
      description: "test",
    },
    tierCodeFontSize: {1: "2.5em", 2: "1.7em", 3: "1.3em"},
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['PROMPT', 'CONFIRM']),

    refreshTiers(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: `tiers` })
      .then(tiers => {
        this.tiers.splice(0);
        this.$nextTick(() => { this.tiers = tiers; });
      })
      .finally(() => { this.loading = false; });
    },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortArea, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.updateOrder,
      });
    },

    updateOrder(e){
      if(e.oldIndex == e.newIndex) return;
      let sort_order = this.sortable.toArray();
      this.API({ method: 'POST', endpoint: `tiers/sort_order`, data: { sort_order }, autosave: 3000 })
    },

    clickEdit(t){
      this.editTier = {
        ...this.editTier,
        ...t,
        price: t.price/100,
        show: true,
      };
    },

    updateTier(){
      this.editTier.loading = true;
      this.API({ method: 'PATCH', endpoint: `tiers/${this.editTier.xid}`, data: {
        code: this.editTier.code.toUpperCase(),
        price: parseInt(parseFloat(this.editTier.price)*100),
        colour: this.editTier.colour,
        description: this.editTier.description,
      }})
      .then(t_updated => {
        this.tiers[this.tiers.findIndex(t=>t.xid == t_updated.xid)] = t_updated;
        this.editTier.show = false;
      })
      .finally(() => { this.editTier.loading = false; });
    },

    duplicate(t){
      this.PROMPT({ title: "Duplicate Tier", message: `New tier code (1-3 letters and/or numbers):`, placeholder: t.code })
      .then(code => {
        this.API({ method: 'POST', endpoint: `tiers/${t.xid}/duplicate`, data: { code: code.toUpperCase() } })
        .then(this.refreshTiers);
      });
    },

    Delete(t){
      this.CONFIRM({ title: "Delete?", message: `Delete tier ${t.code}?`})
      .then(() => {
        this.API({ method: 'DELETE', endpoint: `tiers/${t.xid}` })
        .then(this.refreshTiers);
      });
    },

    create(){
      this.PROMPT({ title: "New Tier", message: `New tier code (1-3 letters and/or numbers):` })
      .then(code => {
        this.API({ method: 'PUT', endpoint: `tiers`, data: { code: code.toUpperCase() } })
        .then(this.refreshTiers);
      });
    },

  },

  mounted() {
    this.makeSortable();
    this.refreshTiers();
  },

};
</script>
